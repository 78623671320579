import { Alert, AlertTitle } from "@mui/material";
import React from "react";

const Mssg = ({ open, mssg, successMssg }) => {
  return (
    <Alert
      sx={{
        position: "fixed",
        top: 20,
        left: "50%",
        transform: open ? "translateX(-50%)" : "translate(-50%, -200%)",
        width: 500,
        transition: ".5s",
        zIndex: 100,
      }}
      severity={mssg === successMssg ? "success" : "error"}
    >
      <AlertTitle>{mssg === successMssg ? "Success" : "Error"}</AlertTitle>
      {mssg}
    </Alert>
  );
};

export default Mssg;
