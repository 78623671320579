import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DashboardTable from "../Components/DashboardTable";
import Mssg from "../Components/Mssg";
import ConfirmationModal from "../Components/ConfirmationModal";

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "credentials",
    numeric: false,
    disablePadding: true,
    label: "Credentials",
  },
  {
    id: "message",
    numeric: true,
    disablePadding: false,
    label: "Message",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Created At",
  },
  {
    disabled: true,
  },
];

const Messages = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [mssg, setMssg] = useState("");
  const [successMssg, setSuccessMssg] = useState("");
  const token = localStorage.getItem("DB_token");
  const [toDelete, setToDelete] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    fetchAll(token);
  }, [token]);

  const fetchAll = async (token) => {
    const res = await fetch("https://api.vr-division.com/api/messages", {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    const d = await res.json();
    setData(
      d.map(
        ({
          id,
          name,
          company,
          email,
          phoneNumber,
          message,
          createdAt,
          industry,
          position,
          country,
        }) => ({
          id,
          credentials: (
            <p style={{ textAlign: "left", whiteSpace: "nowrap" }}>
              Name: {name} <br />
              Company: {company ? company : "-----"} <br />
              Position: {position ? position : "-----"} <br />
              Industry: {industry ? industry : "-----"} <br />
              Country: {country ? country : "-----"} <br />
              Email: {email} <br />
              Phone Number: {phoneNumber} <br />
            </p>
          ),
          message: (
            <p style={{ textAlign: "left" }}>
              {message.split(/\\n/g).map((v) => {
                const match = v.match(
                  /((http|https|ftp):\/\/[\w?=&./-;#~%-]+(?![\w\s?&./;#~%"=-]*>))/g
                );
                return (
                  <>
                    {match?.length ? <a href={match[0]}>{match[0]}</a> : v}
                    <br />
                  </>
                );
              })}
            </p>
          ),
          createdAt,
        })
      )
    );
  };

  const options = [
    {
      inner: "Delete",
      onClick: async (id) => {
        setToDelete(id);
        setModalOpen(true);
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Messages | VR Division | We Create Digital Experiences</title>
      </Helmet>
      <ConfirmationModal
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        mssg={`Do you want to delete message with id ${toDelete}`}
        onConfirm={async () => {
          setSuccessMssg(`Message with id ${toDelete} has been deleted`);
          const res = await fetch(
            `https://api.vr-division.com/api/messages/${toDelete}`,
            {
              method: "DELETE",
              headers: {
                authorization: `Bearer ${token}`,
              },
            }
          );
          const d = await res.json();

          setMssg(d.mssg);
          fetchAll(token);

          setTimeout(() => {
            setOpen(true);
          }, 100);
          setTimeout(() => {
            setOpen(false);
          }, 2000);
        }}
      />
      <Mssg open={open} mssg={mssg} successMssg={successMssg} />
      <DashboardTable
        collapse={false}
        pageName={"Messages"}
        options={options}
        headCells={headCells}
        data={data}
      />
    </>
  );
};

export default Messages;
