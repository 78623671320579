import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import DashboardTable from "../Components/DashboardTable";
import Mssg from "../Components/Mssg";
import ConfirmationModal from "../Components/ConfirmationModal";

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Title",
  },
  {
    id: "experience",
    numeric: false,
    disablePadding: true,
    label: "Experience",
  },
  {
    id: "subTitle",
    numeric: false,
    disablePadding: true,
    label: "Subtitle",
  },
  {
    id: "link",
    numeric: false,
    disablePadding: true,
    label: "Link",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: true,
    label: "Category",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Created At",
  },
  {
    id: "enabled",
    numeric: false,
    disablePadding: true,
    label: "Enabled",
  },
  {
    disabled: true,
  },
];

const Epics = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [mssg, setMssg] = useState("");
  const [successMssg, setSuccessMssg] = useState("");
  const token = localStorage.getItem("DB_token");
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMssg, setModalMssg] = useState("");
  const [method, setMethod] = useState("");
  const [endPoint, setEndPoint] = useState("");

  const options = [
    {
      inner: "Delete",
      onClick: (id) => {
        setModalOpen(true);
        setModalMssg(`Do you want to delete career with id ${id}`);
        setSuccessMssg(`Career with id ${id} has been deleted`);
        setEndPoint(`https://api.vr-division.com/api/careers/${id}`);
        setMethod("DELETE");
      },
    },
    {
      inner: "Edit",
      onClick: (id) => {
        navigate(`/careers/${id}`);
      },
    },
    {
      inner: "Change Status",
      onClick: (id) => {
        setModalOpen(true);
        setModalMssg(`Do you want to change career with id ${id} status`);
        setSuccessMssg(`Career with id ${id} has been changed it's status`);
        setEndPoint(`https://api.vr-division.com/api/careers/status/${id}`);
        setMethod("PATCH");
      },
    },
    {
      inner: "Show detailed",
      onClick: (id) => {
        window.open(`https://vr-division.com/careers/${id}`, "_blank");
      },
    },
  ];

  useEffect(() => {
    fetchAll();
  }, []);

  const fetchAll = async () => {
    const res = await fetch("https://api.vr-division.com/api/careers/db");
    const d = await res.json();
    if (d.mssg === "Request is not authorized") {
      localStorage.removeItem("DB_token");
      localStorage.removeItem("DB_role");
      localStorage.removeItem("DB_id");
      localStorage.removeItem("DB_name");
      navigate("/login");
      return;
    }
    setData(
      d.map(
        ({
          id,
          title,
          experience,
          subTitle,
          link,
          category,
          createdAt,
          enabled,
        }) => ({
          id,
          title,
          experience,
          subTitle,
          link,
          category,
          createdAt,
          enabled: enabled ? "True" : "False",
        })
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>Careers | VR Division | We Create Digital Experiences</title>
      </Helmet>
      <ConfirmationModal
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        mssg={modalMssg}
        onConfirm={async () => {
          const res = await fetch(endPoint, {
            method: method,
            headers: {
              authorization: `Bearer ${token}`,
            },
          });
          const d = await res.json();

          setMssg(d.mssg);
          fetchAll();

          setTimeout(() => {
            setOpen(true);
          }, 100);
          setTimeout(() => {
            setOpen(false);
          }, 2000);
        }}
      />
      <Mssg open={open} mssg={mssg} successMssg={successMssg} />
      <DashboardTable
        collapse={false}
        pageName={"Careers"}
        options={options}
        headCells={headCells}
        data={data}
      />
    </>
  );
};

export default Epics;
