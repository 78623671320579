import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

export const DashboardMenu = ({ mainEl, items }) => {
  const [accountAnchorEl, setAnchorEl] = useState(null);
  const accountOpen = Boolean(accountAnchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={accountOpen ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={accountOpen ? "true" : undefined}
      >
        {mainEl}
      </IconButton>
      {items && (
        <Menu
          anchorEl={accountAnchorEl}
          id="account-menu"
          open={accountOpen}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "10px",
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {items.map(({ inner, onClick, ...item }, index) => (
            <MenuItem key={index} onClick={onClick} {...item}>
              {inner}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
