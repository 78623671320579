import {
  Button,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  keyframes,
} from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet";
import Mssg from "../Components/Mssg";
import { LoopRounded } from "@mui/icons-material";

const spin = keyframes`
from {
  transform: rotate(360deg)
}
to {
  transform: rotate(0deg)
}
`;

const DBUsersAdd = () => {
  const [form, setForm] = useState({
    uid: "",
    name: "",
    pwd: "",
    role: "0",
  });
  const [open, setOpen] = useState(false);
  const [mssg, setMssg] = useState("");
  const token = localStorage.getItem("DB_token");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setMssg("");
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }
    if (!form["uid"] || !form["name"] || !form["pwd"] || !form["role"]) {
      setMssg("Please fill in all fields");
      openM();
      return;
    }
    const res = await fetch("https://api.vr-division.com/api/db_users", {
      method: "POST",
      body: formData,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();
    if (res.ok) {
      setTimeout(() => {
        window.location.reload();
      }, 2100);
    }
    setMssg(data.mssg);
    openM();
  };

  const openM = () => {
    setTimeout(() => {
      setOpen(true);
      setLoading(false);
    }, 100);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  return (
    <>
      <Helmet>
        <title>Add User | VR Division | We Create Digital Experiences</title>
      </Helmet>
      <Typography variant="h5" fontWeight={900} component="h1">
        Add User
      </Typography>
      <Mssg open={open} mssg={mssg} successMssg="User has been added" />
      <form
        style={{
          maxWidth: "600px",
          width: "100%",
          margin: "0 auto",
        }}
        onSubmit={handleSubmit}
      >
        <Stack>
          <InputLabel
            sx={{
              fontSize: 20,
            }}
          >
            Username
          </InputLabel>
          <OutlinedInput
            sx={{
              marginBottom: 2,
            }}
            value={form["uid"]}
            onChange={(e) =>
              setForm((prev) => ({ ...prev, uid: e.target.value }))
            }
          />
          <InputLabel
            sx={{
              fontSize: 20,
            }}
          >
            Name
          </InputLabel>
          <OutlinedInput
            sx={{
              marginBottom: 2,
            }}
            value={form["name"]}
            onChange={(e) =>
              setForm((prev) => ({ ...prev, name: e.target.value }))
            }
          />
          <InputLabel
            sx={{
              fontSize: 20,
            }}
          >
            Password
          </InputLabel>
          <OutlinedInput
            sx={{
              marginBottom: 2,
            }}
            value={form["pwd"]}
            onChange={(e) =>
              setForm((prev) => ({ ...prev, pwd: e.target.value }))
            }
          />
          <InputLabel
            sx={{
              fontSize: 20,
            }}
          >
            Role
          </InputLabel>
          <Select
            sx={{
              marginBottom: 2,
            }}
            value={form["role"]}
            onChange={(e) =>
              setForm((prev) => ({ ...prev, role: e.target.value }))
            }
          >
            <MenuItem value="0" disabled>
              Select a role
            </MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="career">Career</MenuItem>
          </Select>
          <Button
            type="submit"
            disabled={loading}
            variant="contained"
            size="large"
          >
            {loading ? (
              <LoopRounded sx={{ animation: `${spin} 1s ease-out infinite` }} />
            ) : (
              "Add User"
            )}
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default DBUsersAdd;
