import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import { Suspense } from "react";
import { Helmet } from "react-helmet";
import DashboardLayout from "./DashboardLayout";
import DashboardLogin from "./Pages/DashboardLogin";
import DBUsers from "./Pages/DBUsers";
import DBUsersAdd from "./Pages/DBUsersAdd";
import DBUsersEdit from "./Pages/DBUsersEdit";
import DBCareers from "./Pages/DBCareers";
import DBCareersAdd from "./Pages/DBCareersAdd";
import DBCareersEdit from "./Pages/DBCareersEdit";
// import DBBlogs from "./pages/DBBlogs";
// import DBBlogsAdd from "./pages/DBBlogsAdd";
// import DBBlogsEdit from "./pages/DBBlogsEdit";
// import DBImages from "./pages/DBImages";
// import DBImagesAdd from "./pages/DBImagesAdd";
import DBMessages from "./Pages/DBMessages";
import DBSubmissions from "./Pages/DBSubmissions";

const Router = () => {
  const token = localStorage.getItem("DB_token");
  const role = localStorage.getItem("DB_role");
  const navigate = useNavigate();

  const roller = () => {
    switch (role) {
      case "career":
        return "/careers";
      // case "blog":
      //   return "/blogs";
      case "admin":
        return "/users";
      default:
        logout();
    }
  };

  const logout = () => {
    localStorage.removeItem("DB_token");
    localStorage.removeItem("DB_role");
    localStorage.removeItem("DB_id");
    localStorage.removeItem("DB_name");
    navigate("/login");
  };

  return (
    <>
      <Helmet>
        <title>Home | VR Division | We Create Digital Experiences</title>
        <link
          rel="shortcut icon"
          href={require("./Assets/Favicon.png")}
          type="image/x-icon"
        />
      </Helmet>
      <Suspense fallback={""}>
        {useRoutes([
          {
            path: "/",
            element: token ? <DashboardLayout /> : <Navigate to={"/login"} />,
            children: [
              {
                path: "",
                element: <Navigate to={"/login"} />,
              },
              {
                path: "users",
                element: <DBUsers />,
              },
              {
                path: "users/add",
                element: <DBUsersAdd />,
              },
              {
                path: "users/:id",
                element: <DBUsersEdit />,
              },
              {
                path: "careers",
                element: <DBCareers />,
              },
              {
                path: "careers/add",
                element: <DBCareersAdd />,
              },
              {
                path: "careers/:id",
                element: <DBCareersEdit />,
              },
              {
                path: "messages",
                element: <DBMessages />,
              },
              {
                path: "submissions",
                element: <DBSubmissions />,
              },
              {
                path: "*",
                element: <Navigate to={"/login"} />,
              },
            ],
          },
          {
            path: "/login",
            element: token ? <Navigate to={roller()} /> : <DashboardLogin />,
          },
          {
            path: "*",
            element: <Navigate to="/" />,
          },
        ])}
      </Suspense>
    </>
  );
};

export default Router;
