import { Avatar, Box, Stack, Typography } from "@mui/material";
import SideBarList from "./SideBarList";

const SideBar = () => {
  const name = localStorage.getItem("DB_name");
  return (
    <Stack width="350px" borderRight="1px dashed gray">
      <Stack justifyContent="flex-start" direction="row" padding={2}>
        <img
          width={50}
          src={require("../Assets/VectorBlack.png")}
          alt="Site Logo"
        />
      </Stack>
      <Box padding={2}>
        <Stack
          paddingX={1.5}
          paddingY={1}
          bgcolor="#0000000a"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          borderRadius={3}
        >
          <Avatar sx={{ marginRight: 1.5, bgcolor: "primary.main" }} />
          <Typography fontWeight={500}>{name}</Typography>
        </Stack>
      </Box>
      <SideBarList />
    </Stack>
  );
};

export default SideBar;
