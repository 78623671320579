import {
  Button,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Mssg from "../Components/Mssg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const inputs = [
  {
    label: "Title",
    big: false,
    formName: "title",
  },
  {
    label: "Experience",
    big: false,
    formName: "experience",
  },
  {
    label: "Subtitle",
    big: false,
    formName: "subTitle",
  },
  {
    label: "ClickUp Link",
    big: false,
    formName: "link",
  },
];

const quills = [
  {
    label: "Brief",
    formName: "brief",
  },
  {
    label: "Responsibilities",
    formName: "responsibilities",
  },
  {
    label: "Requirements and skills",
    formName: "skills",
  },
];

const DBCareersAdd = () => {
  const [form, setForm] = useState({
    title: "",
    experience: "",
    subTitle: "",
    link: "",
    category: "0",
    brief: "",
    responsibilities: "",
    skills: "",
    img: "",
  });
  const [open, setOpen] = useState(false);
  const [mssg, setMssg] = useState("");
  const token = localStorage.getItem("DB_token");
  const { id } = useParams();
  const [oldImg, setOldImg] = useState("");

  const handleSubmit = async (e) => {
    setMssg("");
    e.preventDefault();
    if (
      !form["title"] ||
      !form["experience"] ||
      !form["subTitle"] ||
      !form["link"] ||
      !form["category"] ||
      !form["brief"] ||
      !form["responsibilities"] ||
      !form["skills"]
    ) {
      setMssg("Please fill in all fields");
      openM();
      return;
    }
    const formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }
    const res = await fetch(`https://api.vr-division.com/api/careers/${id}`, {
      method: "PATCH",
      body: formData,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();
    setMssg(data.mssg);
    openM();
  };

  const openM = () => {
    setTimeout(() => {
      setOpen(true);
    }, 100);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  useEffect(() => {
    (async () => {
      const res = await fetch(`https://api.vr-division.com/api/careers/${id}`);
      const data = await res.json();
      console.log(data);
      setForm({
        title: data.title,
        experience: data.experience,
        subTitle: data.subTitle,
        link: data.link,
        category: data.category,
        brief: data.brief,
        responsibilities: data.responsibilities,
        skills: data.skills,
        img: "",
      });
      setOldImg(data.img);
    })();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>
          Edit Career ({id}) | VR Division | We Create Digital Experiences
        </title>
      </Helmet>
      <Mssg
        open={open}
        mssg={mssg}
        successMssg={`Career with id ${id} has been updated`}
      />
      <Typography variant="h5" fontWeight={900} component="h1">
        Edit Career ({id})
      </Typography>
      <form
        style={{
          maxWidth: "600px",
          width: "100%",
          margin: "0 auto",
        }}
        onSubmit={handleSubmit}
      >
        <Stack>
          {inputs.map(({ label, formName, big }, index) => (
            <Stack key={index}>
              <InputLabel
                sx={{
                  fontSize: 20,
                }}
              >
                {label}
              </InputLabel>
              <OutlinedInput
                sx={{
                  marginBottom: 2,
                }}
                multiline={big}
                rows={4}
                value={form[formName]}
                onChange={(e) =>
                  setForm((prev) => ({ ...prev, [formName]: e.target.value }))
                }
              />
            </Stack>
          ))}
          <InputLabel
            sx={{
              fontSize: 20,
            }}
          >
            Category
          </InputLabel>
          <Select
            sx={{
              marginBottom: 2,
            }}
            value={form["category"]}
            onChange={(e) =>
              setForm((prev) => ({ ...prev, category: e.target.value }))
            }
          >
            <MenuItem value="0" disabled>
              Select a category
            </MenuItem>
            <MenuItem value="3D and Unreal Engine">
              3D and Unreal Engine
            </MenuItem>
            <MenuItem value="Design and Media">Design and Media</MenuItem>
            <MenuItem value="IT and Development">IT and Development</MenuItem>
            <MenuItem value="Operation">Operation</MenuItem>
          </Select>
          {quills.map(({ label, formName }) => (
            <>
              <InputLabel
                sx={{
                  fontSize: 20,
                }}
              >
                {label}
              </InputLabel>
              <ReactQuill
                theme="snow"
                value={form[formName]}
                onChange={(e) =>
                  setForm((prev) => ({ ...prev, [formName]: e }))
                }
                style={{
                  marginBottom: 16,
                }}
                className="smallQL"
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["clean"],
                  ],
                }}
              />
            </>
          ))}
          <Button
            sx={{
              marginBottom: 2,
            }}
            variant="outlined"
            component="label"
            size="large"
          >
            {form["img"] ? form["img"].name : "Add Image"}
            <input
              onChange={(e) => {
                setForm((prev) => ({
                  ...prev,
                  img: e.target.files[0],
                }));
              }}
              type="file"
              id="images"
              hidden
              accept="image/*"
            />
          </Button>
          {oldImg && !form["img"] && (
            <>
              <InputLabel
                sx={{
                  fontSize: 20,
                }}
              >
                Old Image
              </InputLabel>
              <img src={`/careers/${oldImg}`} alt="" />
            </>
          )}
          <Button type="submit" variant="contained" size="large">
            Edit Career
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default DBCareersAdd;
