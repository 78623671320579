import { useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Collapse, List } from "@mui/material";
import { NavLink } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";

const SideBarListItem = ({ item }) => {
  const { text, icon, isCollapse, show } = item;
  const [open, setOpen] = useState(item.active);

  const handleClick = () => {
    setOpen(!open);
  };

  const itemStyle = isCollapse
    ? ""
    : !item.active
    ? ""
    : {
        backgroundColor: "#0000000a",
        color: "primary.main",
      };
  if (show)
    return (
      <>
        <ListItemButton
          component={NavLink}
          to={!isCollapse && item.link}
          onClick={handleClick}
          sx={{
            borderRadius: 2,
            ...itemStyle,
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={text} />
          {isCollapse && (
            <ExpandLess
              style={{
                transition: ".25s ease-out",
                transform: `rotate(${open ? "0deg" : "180deg"})`,
              }}
            />
          )}
        </ListItemButton>
        {isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              sx={{
                pl: 4,
              }}
            >
              {item.collapseList.map(
                ({ innerText, innerIcon, innerLink, active }, index) => {
                  const itemStyle = !active
                    ? ""
                    : {
                        backgroundColor: "#0000000a",
                        color: "primary.main",
                      };
                  return (
                    <ListItemButton
                      key={index}
                      component={NavLink}
                      to={innerLink}
                      sx={{
                        borderRadius: 2,
                        ...itemStyle,
                      }}
                    >
                      <ListItemIcon>{innerIcon}</ListItemIcon>
                      <ListItemText primary={innerText} />
                    </ListItemButton>
                  );
                }
              )}
            </List>
          </Collapse>
        )}
      </>
    );
  return "";
};

export default SideBarListItem;
