import Modal from "@mui/material/Modal";
import { Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledStack = styled(Stack)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  backgroundColor: "white",
  padding: 40,
  outline: "none",
  borderRadius: 4,
}));

const ConfirmationModal = ({
  mssg,
  onConfirm,
  setModalOpen,
  modalOpen = false,
}) => {
  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledStack>
        <Typography mb={2} fontSize={20} fontWeight={700}>
          {mssg}
        </Typography>
        <Stack direction="row" spacing={2} mt={5}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              setModalOpen(false);
              onConfirm();
            }}
            color="success"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            fullWidth
            color="error"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Dismiss
          </Button>
        </Stack>
      </StyledStack>
    </Modal>
  );
};

export default ConfirmationModal;
