import {
  Button,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Mssg from "../Components/Mssg";

const DBUsersAdd = () => {
  const [form, setForm] = useState({
    name: "",
    role: "0",
  });
  const [open, setOpen] = useState(false);
  const [mssg, setMssg] = useState("");
  const token = localStorage.getItem("DB_token");
  const { id } = useParams();

  const handleSubmit = async (e) => {
    setMssg("");
    e.preventDefault();
    const formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }
    if (!form["name"] || !form["role"]) {
      setMssg("Please fill in all fields");
      openM();
      return;
    }
    const res = await fetch(`https://api.vr-division.com/api/db_users/${id}`, {
      method: "PATCH",
      body: formData,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();
    setMssg(data.mssg);
    openM();
  };

  const openM = () => {
    setTimeout(() => {
      setOpen(true);
    }, 100);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  useEffect(() => {
    (async () => {
      const res = await fetch(
        `https://api.vr-division.com/api/db_users/${id}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      console.log(data);
      setForm({
        name: data.name,
        role: data.role,
      });
    })();
  }, [token, id]);

  return (
    <>
      <Helmet>
        <title>
          Edit User ({id}) | VR Division | We Create Digital Experiences
        </title>
      </Helmet>
      <Typography variant="h5" fontWeight={900} component="h1">
        Edit User ({id})
      </Typography>
      <Mssg
        open={open}
        mssg={mssg}
        successMssg={`User with id ${id} has been updated`}
      />
      <form
        style={{
          maxWidth: "600px",
          width: "100%",
          margin: "0 auto",
        }}
        onSubmit={handleSubmit}
      >
        <Stack>
          <InputLabel
            sx={{
              fontSize: 20,
            }}
          >
            Name
          </InputLabel>
          <OutlinedInput
            sx={{
              marginBottom: 2,
            }}
            value={form["name"]}
            onChange={(e) =>
              setForm((prev) => ({ ...prev, name: e.target.value }))
            }
          />
          <InputLabel
            sx={{
              fontSize: 20,
            }}
          >
            Role
          </InputLabel>
          <Select
            sx={{
              marginBottom: 2,
            }}
            value={form["role"]}
            onChange={(e) =>
              setForm((prev) => ({ ...prev, role: e.target.value }))
            }
          >
            <MenuItem value="0" disabled>
              Select a role
            </MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="blog">Blog</MenuItem>
            <MenuItem value="career">Career</MenuItem>
          </Select>
          <Button type="submit" variant="contained" size="large">
            Edit user
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default DBUsersAdd;
