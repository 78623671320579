import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import DashboardTable from "../Components/DashboardTable";
import Mssg from "../Components/Mssg";
import ConfirmationModal from "../Components/ConfirmationModal";

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "uid",
    numeric: false,
    disablePadding: true,
    label: "Usernames",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: true,
    label: "Role",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Created At",
  },
  {
    disabled: true,
  },
];

const Epics = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [mssg, setMssg] = useState("");
  const [successMssg, setSuccessMssg] = useState("");
  const token = localStorage.getItem("DB_token");
  const navigate = useNavigate();
  const [toDelete, setToDelete] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const options = [
    {
      inner: "Delete",
      onClick: async (id) => {
        setToDelete(id);
        setModalOpen(true);
      },
    },
    {
      inner: "Edit",
      onClick: (id) => {
        navigate(`/users/${id}`);
      },
    },
  ];

  useEffect(() => {
    fetchAll(token);
  }, [token]);

  const fetchAll = async (token) => {
    const res = await fetch("https://api.vr-division.com/api/db_users", {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    const d = await res.json();
    if (d.mssg === "Request is not authorized") {
      localStorage.removeItem("DB_token");
      localStorage.removeItem("DB_role");
      localStorage.removeItem("DB_id");
      localStorage.removeItem("DB_name");
      navigate("/login");
      return;
    }
    setData(d);
  };

  return (
    <>
      <Helmet>
        <title>Users | VR Division | We Create Digital Experiences</title>
      </Helmet>
      <ConfirmationModal
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        mssg={`Do you want to delete user with id ${toDelete}`}
        onConfirm={async () => {
          setSuccessMssg(`User with id ${toDelete} has been deleted`);
          const res = await fetch(
            `https://api.vr-division.com/api/db_users/${toDelete}`,
            {
              method: "DELETE",
              headers: {
                authorization: `Bearer ${token}`,
              },
            }
          );
          const d = await res.json();

          setMssg(d.mssg);
          fetchAll(token);

          setTimeout(() => {
            setOpen(true);
          }, 100);
          setTimeout(() => {
            setOpen(false);
          }, 2000);
        }}
      />
      <Mssg open={open} mssg={mssg} successMssg={successMssg} />
      <DashboardTable
        collapse={false}
        pageName={"Users"}
        options={options}
        headCells={headCells}
        data={data}
      />
    </>
  );
};

export default Epics;
