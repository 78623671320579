import {
  Button,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  keyframes,
} from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet";
import Mssg from "../Components/Mssg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { LoopRounded } from "@mui/icons-material";

const spin = keyframes`
from {
  transform: rotate(360deg)
}
to {
  transform: rotate(0deg)
}
`;

const inputs = [
  {
    label: "Title",
    big: false,
    formName: "title",
  },
  {
    label: "Experience",
    big: false,
    formName: "experience",
  },
  {
    label: "Subtitle",
    big: false,
    formName: "subTitle",
  },
  {
    label: "ClickUp Link",
    big: false,
    formName: "link",
  },
];

const quills = [
  {
    label: "Brief",
    formName: "brief",
  },
  {
    label: "Responsibilities",
    formName: "responsibilities",
  },
  {
    label: "Requirements and skills",
    formName: "skills",
  },
];

const DBCareersAdd = () => {
  const [form, setForm] = useState({
    title: "",
    experience: "",
    subTitle: "",
    link: "",
    category: "0",
    brief: "",
    responsibilities: "",
    skills: "",
  });
  const [open, setOpen] = useState(false);
  const [mssg, setMssg] = useState("");
  const token = localStorage.getItem("DB_token");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setMssg("");
    setLoading(true);
    e.preventDefault();
    if (
      !form["title"] ||
      !form["experience"] ||
      !form["subTitle"] ||
      !form["link"] ||
      !form["category"]
    ) {
      setMssg("Please fill in all fields");
      openM();
      return;
    }
    const formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }
    const res = await fetch("https://api.vr-division.com/api/careers", {
      method: "POST",
      body: formData,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();
    if (res.ok) {
      setTimeout(() => {
        window.location.reload();
      }, 2100);
    }
    setMssg(data.mssg);
    openM();
  };

  const openM = () => {
    setTimeout(() => {
      setLoading(false);
      setOpen(true);
    }, 100);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  return (
    <>
      <Helmet>
        <title>Add Career | VR Division | We Create Digital Experiences</title>
      </Helmet>
      <Mssg open={open} mssg={mssg} successMssg="Career has been added" />
      <Typography variant="h5" fontWeight={900} component="h1">
        Add Career
      </Typography>
      <form
        style={{
          maxWidth: "600px",
          width: "100%",
          margin: "0 auto",
        }}
        onSubmit={handleSubmit}
      >
        <Stack>
          {inputs.map(({ label, formName, big }, index) => (
            <Stack key={index}>
              <InputLabel
                sx={{
                  fontSize: 20,
                }}
              >
                {label}
              </InputLabel>
              <OutlinedInput
                sx={{
                  marginBottom: 2,
                }}
                multiline={big}
                rows={4}
                value={form[formName]}
                onChange={(e) =>
                  setForm((prev) => ({ ...prev, [formName]: e.target.value }))
                }
              />
            </Stack>
          ))}
          <InputLabel
            sx={{
              fontSize: 20,
            }}
          >
            Category
          </InputLabel>
          <Select
            sx={{
              marginBottom: 2,
            }}
            value={form["category"]}
            onChange={(e) =>
              setForm((prev) => ({ ...prev, category: e.target.value }))
            }
          >
            <MenuItem value="0" disabled>
              Select a category
            </MenuItem>
            <MenuItem value="3D Artist">3D Artist</MenuItem>
            <MenuItem value="Unreal Engine Artist">
              Unreal Engine Artist
            </MenuItem>
            <MenuItem value="Design and Media">Design and Media</MenuItem>
            <MenuItem value="IT and Development">IT and Development</MenuItem>
            <MenuItem value="Operation">Operation</MenuItem>
          </Select>
          {quills.map(({ label, formName }) => (
            <>
              <InputLabel
                sx={{
                  fontSize: 20,
                }}
              >
                {label}
              </InputLabel>
              <ReactQuill
                theme="snow"
                value={form[formName]}
                onChange={(e) =>
                  setForm((prev) => ({ ...prev, [formName]: e }))
                }
                style={{
                  marginBottom: 16,
                }}
                className="smallQL"
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["clean"],
                  ],
                }}
              />
            </>
          ))}
          <Button
            type="submit"
            disabled={loading}
            variant="contained"
            size="large"
          >
            {loading ? (
              <LoopRounded sx={{ animation: `${spin} 1s ease-out infinite` }} />
            ) : (
              "Add Career"
            )}
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default DBCareersAdd;
