import { createTheme, ThemeProvider } from "@mui/material/styles";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";

const theme = createTheme({
  palette: {
    bg: {
      main: "#090909",
    },
    primary: {
      dark: "#1190AC",
      dark1: "#005DBC",
      main: "#12C2E9",
      main1: "#007EFE",
      light: "#D0E9EF",
      light1: "#B1D8FF",
      contrastText: "white",
    },
    secondary: {
      dark: "#FF0C15",
      main: "#FE4D54",
      light: "#FFCFD1",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
