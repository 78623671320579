import {
  Box,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TableRow as Tablerow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const TableRow = ({ collapse, options, row, isSelected, list }) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isItemSelected = isSelected(row.name);

  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tablerow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.name}
        selected={isItemSelected}
      >
        {collapse && (
          <TableCell align="center">
            <IconButton onClick={() => setCollapseOpen(!collapseOpen)}>
              <KeyboardArrowDownIcon
                sx={{
                  transition: ".25s",
                  transform: collapseOpen ? "rotate(180deg)" : "",
                }}
              />
            </IconButton>
          </TableCell>
        )}
        {row.map((item, index) => (
          <TableCell key={index} align="center">
            {item}
            {/* <div dangerouslySetInnerHTML={{ __html: item }}></div> */}
          </TableCell>
        ))}
        {!list && (
          <TableCell align="center" sx={{ width: "fit-content" }}>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleMenuClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: "20ch",
                },
              }}
            >
              {options.map(({ inner, onClick, ...props }) => (
                <MenuItem
                  key={inner}
                  onClick={() => {
                    onClick(row[0]);
                    handleClose();
                  }}
                  {...props}
                  sx={{
                    display: "block",
                    textAlign: "left",
                  }}
                >
                  {inner}
                </MenuItem>
              ))}
            </Menu>
          </TableCell>
        )}
      </Tablerow>
      {list &&
        row.map((item) => {
          if (typeof item === "object")
            return (
              <Tablerow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
                    <Stack padding={2}>
                      <Typography>Title: </Typography>
                      <Typography
                        sx={{
                          direction: "rtl",
                          textAlign: "right",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography>Body:</Typography>
                      <Box
                        sx={{
                          direction: "rtl",
                          textAlign: "right",
                        }}
                      >
                        {item.body}
                      </Box>
                      <Typography>Main Image:</Typography>
                      {/* <Box
                        sx={{
                          backgroundImage: `url(${require(`../../img/${item.img}`)})`,
                          width: "50%",
                          paddingBottom: "25%",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          borderRadius: "10px",
                          marginBottom: "20px",
                          margin: "0 auto",
                        }}
                      ></Box> */}
                    </Stack>
                  </Collapse>
                </TableCell>
              </Tablerow>
            );
          else return "";
        })}
    </>
  );
};

export default TableRow;
