import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import DashboardTable from "../Components/DashboardTable";

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "age",
    numeric: false,
    disablePadding: true,
    label: "Age",
  },
  {
    id: "country",
    numeric: false,
    disablePadding: true,
    label: "Country",
  },
  {
    id: "final",
    numeric: false,
    disablePadding: true,
    label: "Final",
  },
  {
    id: "files",
    numeric: false,
    disablePadding: true,
    label: "Files",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Created At",
  },
  {
    disabled: true,
  },
];

const DBSubmissions = () => {
  const [data, setData] = useState([]);
  const token = localStorage.getItem("DB_token");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const res = await fetch("https://api.vr-division.com/api/xSPECTAR", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      const d = await res.json();
      if (d.mssg === "Request is not authorized") {
        localStorage.removeItem("DB_token");
        localStorage.removeItem("DB_role");
        localStorage.removeItem("DB_id");
        localStorage.removeItem("DB_name");
        navigate("/login");
        return;
      }
      setData(
        d.map(
          ({
            id,
            name,
            email,
            age,
            country,
            final,
            modboard,
            imgs,
            vid,
            fbx,
            createdAt,
          }) => ({
            id,
            name,
            email,
            age,
            country,
            final,
            files: (
              <>
                {modboard && (
                  <a
                    href={`https://vr-division.com/XSPECTAR/${modboard}`}
                    download={modboard}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Modboard
                  </a>
                )}
                <br />
                {imgs && (
                  <a
                    href={`https://vr-division.com/XSPECTAR/${imgs}`}
                    download={imgs}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Images
                  </a>
                )}
                <br />
                {vid && (
                  <a
                    href={`https://vr-division.com/XSPECTAR/${vid}`}
                    download={vid}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Video
                  </a>
                )}
                <br />
                {fbx && (
                  <a
                    href={`https://vr-division.com/XSPECTAR/${fbx}`}
                    download={fbx}
                    target="_blank"
                    rel="noreferrer"
                  >
                    FBX file
                  </a>
                )}
              </>
            ),
            createdAt,
          })
        )
      );
    })();
  }, [token, navigate]);

  return (
    <>
      <Helmet>
        <title>Submissions | VR Division | We Create Digital Experiences</title>
      </Helmet>
      <DashboardTable
        collapse={false}
        pageName={"Submissions"}
        headCells={headCells}
        data={data}
        list={true}
      />
    </>
  );
};

export default DBSubmissions;
