import { Stack, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DashboardMenu } from "./DashboardMenu";

const AppBar = () => {
  const navigate = useNavigate();
  return (
    <Stack
      width="100%"
      direction="row"
      padding={2}
      justifyContent="flex-end"
      alignItems="center"
    >
      <Stack direction="row" justifyContent="center" alignItems="center">
        <DashboardMenu
          mainEl={
            <Avatar sx={{ width: 30, height: 30, bgcolor: "primary.main" }} />
          }
          items={[
            {
              inner: "Logout",
              onClick: () => {
                localStorage.removeItem("DB_token");
                localStorage.removeItem("DB_role");
                localStorage.removeItem("DB_id");
                localStorage.removeItem("DB_name");
                navigate("//login");
              },
            },
          ]}
        />
      </Stack>
    </Stack>
  );
};

export default AppBar;
