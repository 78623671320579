import List from "@mui/material/List";
import SideBarListItem from "./SideBarListItem";
import { useLocation } from "react-router-dom";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import WorkTwoToneIcon from "@mui/icons-material/WorkTwoTone";
// import ArticleTwoToneIcon from "@mui/icons-material/ArticleTwoTone";
import ForumTwoToneIcon from "@mui/icons-material/ForumTwoTone";
import ViewListTwoToneIcon from "@mui/icons-material/ViewListTwoTone";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
// import PermMediaTwoToneIcon from "@mui/icons-material/PermMediaTwoTone";

export default function SideBarList() {
  const { pathname } = useLocation();
  const role = localStorage.getItem("DB_role");

  const list = [
    {
      show: role === "admin",
      text: "Users",
      icon: <PeopleAltTwoToneIcon />,
      isCollapse: true,
      active: pathname === "/users" || pathname === "/users/add",
      collapseList: [
        {
          innerText: "All Users",
          innerIcon: (
            <ViewListTwoToneIcon
              color={pathname === "/users" ? "primary" : ""}
            />
          ),
          innerLink: "/users",
          active: pathname === "/users",
        },
        {
          innerText: "Add User",
          innerIcon: (
            <AddCircleTwoToneIcon
              color={pathname === "/users/add" ? "primary" : ""}
            />
          ),
          innerLink: "/users/add",
          active: pathname === "/users/add",
        },
      ],
    },
    {
      show: role === "admin" || role === "career",
      text: "Careers",
      icon: <WorkTwoToneIcon />,
      isCollapse: true,
      active: pathname === "/careers" || pathname === "/careers/add",
      collapseList: [
        {
          innerText: "All Careers",
          innerIcon: (
            <ViewListTwoToneIcon
              color={pathname === "/careers" ? "primary" : ""}
            />
          ),
          innerLink: "/careers",
          active: pathname === "/careers",
        },
        {
          innerText: "Add Career",
          innerIcon: (
            <AddCircleTwoToneIcon
              color={pathname === "/careers/add" ? "primary" : ""}
            />
          ),
          innerLink: "/careers/add",
          active: pathname === "/careers/add",
        },
      ],
    },
    {
      show: role === "admin",
      text: "Submissions",
      icon: (
        <ViewListTwoToneIcon
          color={pathname === "/submissions" ? "primary" : ""}
        />
      ),
      isCollapse: false,
      link: "/submissions",
      active: pathname === "/submissions",
    },
    {
      show: role === "admin",
      text: "Messages",
      icon: (
        <ForumTwoToneIcon color={pathname === "/messages" ? "primary" : ""} />
      ),
      isCollapse: false,
      link: "/messages",
      active: pathname === "/messages",
    },
  ];

  return (
    <List
      sx={{
        overflow: "auto",
        padding: 1,
      }}
      height="100%"
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {list.map((item, index) => (
        <SideBarListItem key={index} item={item} />
      ))}
    </List>
  );
}
